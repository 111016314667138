import React from 'react'
import { Container } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import Layout from '../components/Layouts/PageLayout'
import PageHeader from '../components/Items/PageHeader'
import MapGeo from '../components/Map/MapGeo'

const Offices = ({ t, pageContext }) => {
  return (
    <Layout pageContext={pageContext}>
      <PageHeader title={t('offices.title')} intro={t('offices.intro')} />
      <Container className='offices'>
        <MapGeo lat={13.7245601} lng={100.4930274} title='Unsere Büro' />
      </Container>
    </Layout>
  )
}

export default withTranslation()(Offices)
